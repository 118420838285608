/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq";
import Features from "pages/Support/HelpCenter/sections/Features";
import Contact from "pages/Support/HelpCenter/sections/Contact";
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg3.jpg";

function HelpCenter() {
  const [collapse, setCollapse] = useState(false);
  const headerRef = useRef(null);
  const updateProperties = (elem, state) => {
    elem.style.setProperty("--x", `${state.x}px`);
    elem.style.setProperty("--y", `${state.y}px`);
    elem.style.setProperty("--width", `${state.width}px`);
    elem.style.setProperty("--height", `${state.height}px`);
    elem.style.setProperty("--radius", state.radius);
    elem.style.setProperty("--scale", state.scale);
  };

  document.querySelectorAll(".cursor").forEach((cursor) => {
    let onElement;

    const createState = (e) => {
      const defaultState = {
        x: e.clientX,
        y: e.clientY,
        width: 40,
        height: 40,
        radius: "50%",
      };

      const computedState = {};

      if (onElement != null) {
        const { top, left, width, height } = onElement.getBoundingClientRect();
        const radius = window.getComputedStyle(onElement).borderTopLeftRadius;

        computedState.x = left + width / 2;
        computedState.y = top + height / 2;
        computedState.width = width;
        computedState.height = height;
        computedState.radius = radius;
      }

      return {
        ...defaultState,
        ...computedState,
      };
    };

    document.addEventListener("mousemove", (e) => {
      const state = createState(e);
      updateProperties(cursor, state);
    });

    document.querySelectorAll("a, button").forEach((elem) => {
      elem.addEventListener("mouseenter", () => (onElement = elem));
      elem.addEventListener("mouseleave", () => (onElement = undefined));
    });
  });
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -4,
    });

    return () => parallax.destroy();
  }, []);
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
          label: "Commencer",
          color: "info",
        }}
        sticky
      />
      <div style={{ backgroundColor: "#0F2022" }}>
        <MKBox
          ref={headerRef}
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.7),
                rgba(gradients.dark.state, 0.7)
              )}, url("https://fnbreport.ph/wp-content/uploads/2018/07/Open-Kitchen-1200x800.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h2"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="f">Qui sommes nous ?</span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "#0B191B",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
          bgColor="#0F2022"
        >
          {/* Trois Axes */}
          <MKBox component="section" py={6} mt={6}>
            <Container>
              <Grid container spacing={3} item xs={12} lg={8} mx="auto">
                <MKTypography component="h5" opacity={0.7}>
                  <span className="fontKThin">Notre Equipe</span>
                </MKTypography>
                <MKTypography variant="h3" mb={3}>
                  <span className="fontKionaGold">Une équipe faites pour livbaczo ajezd</span>
                </MKTypography>
                <MKTypography variant="body1" textAlign="justify">
                  <span className="fontParaPetit">
                    Nous sommes une équipe de professionnels compétents et passionnés. Ce qui nous
                    distingue, c&apos;est notre vision pour l&apos;avenir. Nous sommes animés par la
                    conviction que nous pouvons créer des solutions innovantes qui permette de
                    mettre en place des cercles vertueux bénéfiques à tous les acteurs impliqués.
                    <br /> <br />
                    Notre vision est de contribuer à l&apos;émergence d&apos;un monde où les
                    entreprises, les consommateurs et la société dans son ensemble peuvent prospérer
                    ensemble. Nous pensons qu&apos;il est possible de créer des solutions qui
                    offrent des avantages pour toutes les parties prenantes. En effet, nous sommes
                    convaincus que les entreprises peuvent réaliser des profits tout en ayant un
                    impact positif sur la société.
                    <br /> <br />
                    Notre équipe partage cette vision commune et travaille pour trouver des
                    solutions innovantes pour nos clients. Nous sommes enthousiastes à l&apos;idée
                    de partager notre vision et de collaborer avec vous.
                  </span>
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>
          {/* Nos valeurs */}
          <MKBox component="section" py={6}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                lg={6}
                sx={{ mx: "auto", pb: 3, textAlign: "center" }}
              >
                {/* <MKTypography variant="h4" color="info" textGradient>
                  <span className="fontKionaGold">Nos valeurs</span>
                </MKTypography> */}
                <MKTypography variant="h2" my={1}>
                  <span className="fontKionaGold">Nos valeurs</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontParaPetit">
                    We&apos;re constantly trying to express ourselves and actualize our dreams. If
                    you have the opportunity to play
                  </span>
                </MKTypography>
              </Grid>
              <Grid container spacing={3} sx={{ mt: 3 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <DefaultInfoCard
                    icon="groups"
                    color="light"
                    title={<span className="bigWordThin">Responsabilité sociale</span>}
                    description={
                      <span className="fontParaPetit">
                        Notre ambition est collective et nous sommes obsédés par la conception de
                        cercles vertueux profitant à tous les acteurs impliqués.
                      </span>
                    }
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DefaultInfoCard
                    icon="emoji_events"
                    color="light"
                    title={<span className="bigWordThin">Excellence</span>}
                    description={
                      <span className="fontParaPetit">
                        Nous avons à cœur de livrer un travail d&apos;excellence et nous y apportons
                        une dose généreuse de passion et d&apos;investissement personnel.
                      </span>
                    }
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
                  <DefaultInfoCard
                    icon="lightbulb"
                    color="light"
                    title={<span className="bigWordThin">Innovation</span>}
                    description={
                      <span className="fontParaPetit">
                        Ce que vous voyez n’est qu&apos;un aperçu de notre engagement à rester à
                        l&apos;avant-garde de notre secteur.
                      </span>
                    }
                    direction="center"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: 3 }}>
                <Grid item xs={12} md={6} lg={4} />
                <Grid item xs={12} md={6} lg={4}>
                  <DefaultInfoCard
                    icon="star"
                    color="light"
                    title={<span className="bigWordThin">Exclusivité</span>}
                    description={
                      <span className="fontParaPetit">
                        Nous sommes fiers de notre attachement à l&apos;exclusivité et à
                        l&apos;exceptionnel, et nous travaillons dur pour proposer des produits
                        répondent aux attentes les plus élevées de nos clients.
                      </span>
                    }
                    direction="center"
                  />
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* FAQ */}
          <MKBox component="section" py={8}>
            <Container>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={6} my={6}>
                  <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
                    <span className="fontKionaGold">Questions fréquentes</span>
                  </MKTypography>
                  <MKBox mb={2}>
                    <MKTypography variant="body1" align="center" color="text">
                      <span className="fontPara">
                        Vous pouvez également nous contacter par email pour d&apos;autres questions:
                        contact@tablaba.com
                      </span>
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FaqCollapse
                    title={<span className="fontBold">Question 1</span>}
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 2</span>}
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 3</span>}
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 4</span>}
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 5</span>}
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* <Features /> */}
          {/* Nous contacter */}
          <MKBox component="section" py={8}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={10}
                lg={7}
                mx="auto"
                mb={{ xs: 0, md: 6 }}
                textAlign="center"
              >
                <MKTypography variant="h2" mb={1}>
                  <span className="fontKionaGold">Nous Contacter</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontPara">
                    Nous tâchons de répondre dans les plus brefs délais à vos demandes.
                  </span>
                </MKTypography>
              </Grid>
              <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                  <MKBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput label={<span className="fontPara">Nom</span>} fullWidth success />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          type="email"
                          label={<span className="fontPara">Email</span>}
                          fullWidth
                          success
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          label={<span className="fontPara">Email</span>}
                          multiline
                          fullWidth
                          rows={6}
                          success
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={6}>
                      <MKButton type="submit" variant="outlined" color="goldCustom">
                        Envoyer
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
        </Card>
      </div>
      <MKBox pt={0} px={0} mt={0} bgColor="#0F2022">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HelpCenter;
