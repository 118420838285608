/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { useEffect, useRef } from "react";
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
// import Divider from "@mui/material/Divider";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MKInput from "components/MKInput";
import ReactPlayer from "react-player";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBadge from "components/MKBadge";
// import InfoBackgroundCard from "examples/Cards/BackgroundCards/InfoBackgroundCard";
// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

// Images
// import bgFront from "assets/images/rotating-card-bg-front.jpeg";
// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
// import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCardLanding";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// Coworking page sections
// import Places from "pages/LandingPages/Coworking/sections/Places";
// HelpCenter page components

// import Banner from "pages/Apps/DesktopApp/sections/Banner";
import bgPattern from "assets/images/shapes/pattern-lines.svg";

// Images
// import bgImage2 from "assets/images/examples/color3.jpg";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import styles from "./mystyle.module.css";
import "./style.css";

// Images
// import bgImage from "assets/images/bg-coworking.jpeg";
// import bgImage from "assets/images/bg-eiffeltour.jpg";

function Home() {
  const headerRefSec1 = useRef(null);
  const headerRef = useRef(null);
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -4,
    });

    return () => parallax.destroy();
  }, []);
  useEffect(() => {
    const updateProperties = (elem, state) => {
      elem.style.setProperty("--x", `${state.x}px`);
      elem.style.setProperty("--y", `${state.y}px`);
      elem.style.setProperty("--width", `${state.width}px`);
      elem.style.setProperty("--height", `${state.height}px`);
      elem.style.setProperty("--radius", state.radius);
      elem.style.setProperty("--scale", state.scale);
    };

    document.querySelectorAll(".cursor").forEach((cursor) => {
      let onElement;

      const createState = (e) => {
        const defaultState = {
          x: e.clientX,
          y: e.clientY,
          width: 40,
          height: 40,
          radius: "50%",
        };

        const computedState = {};

        if (onElement != null) {
          const { top, left, width, height } = onElement.getBoundingClientRect();
          const radius = window.getComputedStyle(onElement).borderTopLeftRadius;

          computedState.x = left + width / 2;
          computedState.y = top + height / 2;
          computedState.width = width;
          computedState.height = height;
          computedState.radius = radius;
        }

        return {
          ...defaultState,
          ...computedState,
        };
      };

      document.addEventListener("mousemove", (e) => {
        const state = createState(e);
        updateProperties(cursor, state);
      });

      document.querySelectorAll("a, button").forEach((elem) => {
        elem.addEventListener("mouseenter", () => (onElement = elem));
        elem.addEventListener("mouseleave", () => (onElement = undefined));
      });
    });
    const parallax = new Rellax(headerRefSec1.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
          label: "Commencer",
          color: "info",
        }}
        sticky
      />
      {/* First section */}
      <MKBox
        component="section"
        display="grid"
        position="relative"
        minHeight="90vh"
        mr={{ xs: 0, lg: 0 }}
        mb={{ xs: 0, lg: 0 }}
        sx={{
          overflow: { xs: "hidden", lg: "visible" },
          placeItems: "center",
          backgroundColor: "#102022",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
        }}
        ref={headerRefSec1}
        width="100%"
      >
        {/* <MKBox
          component="img"
          src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1677592438/582802-photos-le-cafe-de-l-homme_sqttzl_e7p1ni.jpg"
          alt=""
          width={{ xs: "100%", lg: "50%" }}
          height={{ xs: "100%", lg: "100%" }}
          position="absolute"
          right={0}
          bottom={{ xs: "-25%", lg: "unset" }}
          top={{ xs: 0, lg: "unset" }}
          sx={{
            objectFit: "cover",
            borderTopLeftRadius: 0,
          }}
          ref={headerRef}
        /> */}
        <MKBox
          alt=""
          width={{ xs: "100%", lg: "50%" }}
          height={{ xs: "100%", lg: "100%" }}
          position="absolute"
          right={0}
          bottom={{ xs: "-25%", lg: "unset" }}
          top={{ xs: 0, lg: "unset" }}
          sx={{
            objectFit: "cover",
            borderTopLeftRadius: 0,
          }}
          ref={headerRef}
        >
          <ReactPlayer
            playing="true"
            loop="true"
            volume="0"
            width="180%"
            height="900px"
            url="https://res.cloudinary.com/dbt1oxzwd/video/upload/v1679303963/RESTAURANT_BAR_DESIGN__Follow_the_Follower_anrtdt.mp4"
          />
        </MKBox>
        <Container>
          <Grid
            container
            spacing={{ xs: 0, lg: 3 }}
            sx={{ mt: { xs: 0, lg: 12 } }}
            flexDirection="row"
          >
            <Grid item xs={12} lg={7} justifyContent="center" flexDirection="column">
              <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                shadow="lg"
                borderRadius="xl"
                style={{ borderWidth: "3px" }}
                sx={{
                  backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                    rgba(white.main, 0.2),
                  backdropFilter: "saturate(200%) blur(30px)",
                  px: { xs: 3, sm: 0 },
                  py: { xs: 3, sm: 6 },
                  mb: { xs: 0, lg: 10 },
                  mt: { xs: 0, lg: -6 },
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  md={10}
                  justifyContent={{ xs: "center", md: "start" }}
                  sx={{ textAlign: { xs: "center", md: "left" } }}
                >
                  <MKTypography
                    variant="h1"
                    color="dark"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                    })}
                  >
                    <span className={styles.titleGrad}>Tablaba</span>
                  </MKTypography>
                  <svg id="rotating-text" viewBox="0 0 500 500">
                    <defs>
                      <path
                        d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250"
                        id="circle"
                      />
                    </defs>
                    <text dy="70">
                      <textPath xlinkHref="#circle">solution ● gratuite ● solution</textPath>
                    </text>
                  </svg>

                  <MKTypography
                    variant="body1"
                    mt={0}
                    // pr={{ md: 12, lg: 24, xl: 32 }}
                    opacity={0.8}
                  >
                    <span className="fontKionaGoldBold" style={{ paddingBottom: "20px" }}>
                      Une solution gratuite à impact immédiat
                    </span>
                    <br />
                    <span className="fontParaWhite">
                      Le premier et unique système de réservation en 3D, doté d&apos;une
                      tarification dynamique, qui permet de proposer à vos clients une expérience de
                      réservation ultime tout en maximisant vos revenus.
                    </span>
                    <br />
                  </MKTypography>
                  <Stack direction="row" spacing={1} mt={3} mb={1}>
                    <MKButton variant="outlined" color="goldCustom" className="fontClassic">
                      Commencer
                    </MKButton>
                  </Stack>
                </Grid>
              </MKBox>
            </Grid>
            {/* <Grid item xs={12} lg={4} justifyContent="center" flexDirection="column">
              <div className={styles.imageFirstSec}>
                <MKBox
                  component="img"
                  src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1677769741/Section_Bilan-removebg-preview_1_rzawqa.png"
                  alt="increase"
                  borderRadius="lg"
                  width="35%"
                  top={0}
                  right={15}
                  position="absolute"
                  zIndex={1}
                  sx={{
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                      rgba(white.main, 0.5),
                    backdropFilter: "saturate(200%) blur(30px)",
                  }}
                />
              </div>
            </Grid> */}
          </Grid>
        </Container>
      </MKBox>
      <div className="divGen">
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "rgba(11, 25, 27,1)",
            // backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          {/* 3 features */}
          <MKBox component="section" py={12}>
            <Container>
              <Grid
                container
                item
                xs={12}
                lg={10}
                justifyContent="center"
                mx="auto"
                textAlign="center"
                pb={6}
              >
                <MKTypography variant="h1" mb={1}>
                  <span className="fontThin">Faites passer votre établissement au </span>
                  <br />
                  <span className="fontKionaGold">niveau supérieur</span>
                </MKTypography>
                {/* <MKTypography variant="body1" color="text">
                  We&apos;re constantly trying to express ourselves and actualize our dreams. If you
                  have the opportunity to play{" "}
                </MKTypography> */}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <SimpleInfoCard
                    color="white"
                    icon="people"
                    title={
                      <>
                        <span className="bigWordThin">Stabilisez</span>
                        <span className="fontThin"> le flux de clients</span>
                      </>
                    }
                    description={
                      <span className="fontParaPetit">
                        Encouragez les visites en dehors des heures de pointe pour maintenir un flux
                        de clients stable.
                      </span>
                    }
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <SimpleInfoCard
                    color="white"
                    icon="euro"
                    title={
                      <>
                        <span className="bigWordThin">Augmentez</span>
                        <span className="fontThin"> vos revenus</span>
                      </>
                    }
                    description={
                      <span className="fontParaPetit">
                        Associez vos meilleures tables à une tarification dynamique et accéder à une
                        source de revenus inexploitée.
                      </span>
                    }
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <SimpleInfoCard
                    color="white"
                    icon="star"
                    title={
                      <>
                        <span className="bigWordThin">Optimisez</span>
                        <span className="fontThin"> l&apos;expérience{/*  client */}</span>
                      </>
                    }
                    descriptiodn="Offrez à vos clients une immersion en haute définition et en 3D dans votre établissement."
                    description={
                      <span className="fontParaPetit">
                        Offrez à vos clients une immersion en haute définition et en 3D dans votre
                        établissement.
                      </span>
                    }
                    direction="center"
                  />
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* Tablaba s'adapte */}
          <MKBox
            variant="gradient"
            bgColor="dark"
            position="relative"
            borderRadius="xl"
            mx={{ xs: 2, xl: 3, xxl: 16 }}
            mt={0}
            py={10}
            px={3}
            sx={{ overflow: "hidden" }}
          >
            <MKBox
              component="img"
              src={bgPattern}
              alt="pattern-lines"
              position="absolute"
              top={0}
              left={0}
              bgColor="#0F2022"
              width="100%"
              opacity={0.6}
            />
            <Container sx={{ position: "relative" }}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={5}
                  py={{ xs: 0, sm: 0 }}
                  mr="auto"
                  position="relative"
                >
                  <MKTypography variant="h2" color="white" mb={3} className="fontThin">
                    <span className="fontKThin">Tablaba</span>{" "}
                    <span className="fontKionaGold">s&apos;adapte</span>
                    <span className="fontKThin"> à votre système de réservation</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="white" mb={0}>
                    Nous ne sommes pas un service de plus à gérer !
                  </MKTypography>
                  <MKTypography variant="body1" color="white" mb={6}>
                    Au contraire, nous nous adaptons aux meilleures solutions de réservation
                    existante.
                  </MKTypography>
                  <MKButton variant="gradient" color="light">
                    Commencer
                  </MKButton>
                  {/* <MKButton variant="text" color="white" sx={{ ml: 1 }}>
                    Read more
                  </MKButton> */}
                </Grid>
                <Grid item xs={12} position="absolute" left="60%" mr={-32} width="55%">
                  <MKBox
                    component="img"
                    src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678896058/partnerlogo_lmeudh.png"
                    alt="macbook"
                    width="50%"
                    display={{ xs: "none", md: "block" }}
                  />
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* Notre service */}
          <MKBox component="section" py={6} mt={8}>
            <div id="serivces">
              <Container>
                {/* Header */}
                <Grid
                  container
                  item
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  xs={10}
                  lg={5}
                  sx={{ mx: "auto", textAlign: "center" }}
                >
                  <MKBadge
                    variant="contained"
                    color="light"
                    badgeContent="Unique"
                    container
                    circular
                    sx={{ mb: 1 }}
                  />
                  <MKTypography variant="h1" mt={0}>
                    <span className="fontKionaGold">Notre service</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    Nous évoluons constamment nos technologies afin d&apos;être ce qui se fait de
                    mieux.
                  </MKTypography>
                </Grid>
                {/* Experience imersive */}
                <Grid container spacing={2} alignItems="center" sx={{ mt: 12 }}>
                  <Grid item xs={12} md={5.5} sx={{ ml: "auto" }}>
                    <DefaultBackgroundCard
                      image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678912440/Hawksmoor_Restaurant_hire_London_1_5de4293b5e_gkm2mq.avif"
                      label="3D Booking"
                      title="Réservez la table de votre choix"
                      description=""
                      action={{
                        type: "internal",
                        route: "/pages/support/help-center",
                        label: "Commencer",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }} lg={5.5}>
                    <MKTypography variant="h2" my={1} mb={3}>
                      <span className="fontKThin">Une expérience </span>
                      <span className="bigWordThin">immersive</span>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" textAlign="justify" mb={1}>
                      <span className="fontPara">
                        Permettez à vos clients de s&apos;immerger dans l&apos;atmosphère des lieux
                        avant même leur arrivée.{" "}
                      </span>
                      <span className="fontPara">
                        Ils peuvent désormais explorer votre établissement et choisir la table qui
                        convient à leurs besoins.
                      </span>
                    </MKTypography>
                  </Grid>
                </Grid>
                {/* <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} /> */}
                {/* Tarification dynamique */}
                <Grid container spacing={3} my={5} alignItems="center">
                  <Grid item xs={12} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }} lg={5.5}>
                    <MKTypography variant="h2" my={-4} mb={3}>
                      <span className="fontKThin"> Une tarification</span>
                      <span className="bigWordThin"> dynamique</span>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" textAlign="justify" mb={1}>
                      <span className="fontPara">
                        Alors que de nombreuses industries ont adopté la tarification dynamique pour
                        améliorer leur rentabilité et l&apos;expérience client, la restauration
                        n&apos;a pas suivi le mouvement.
                      </span>
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} md={5.5} sx={{ ml: "auto" }}>
                    <MKBox component="section" py={2}>
                      <Container>
                        <Grid container spacing={3} mb={6}>
                          <Grid container spacing={3} item xs={12} md={6} lg={12}>
                            <Grid item xs={12} md={6}>
                              <RotatingCard>
                                <RotatingCardFront
                                  image="https://www.transportenvironment.org/wp-content/uploads/2021/08/AdobeStock_plane-runway-1920x1217.jpg"
                                  icon="flight"
                                  title={<>Aviation</>}
                                  color="dark"
                                  description="Voir plus"
                                />
                                <RotatingCardBack
                                  color="dark"
                                  image="https://www.transportenvironment.org/wp-content/uploads/2021/08/AdobeStock_plane-runway-1920x1217.jpg"
                                  title=""
                                  description="La demande est un facteur clé de la tarification dynamique dans l'aviation commerciale."
                                  action={{ type: "internal", route: "/", label: "" }}
                                />
                              </RotatingCard>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RotatingCard>
                                <RotatingCardFront
                                  image="https://w0.peakpx.com/wallpaper/240/377/HD-wallpaper-bedroom-hotel-room-light-design-modern-apartment-interior-idea-modern-design.jpg"
                                  icon="hotel"
                                  title={<>Hôtelerie</>}
                                  color="dark"
                                  description="Voir plus"
                                />
                                <RotatingCardBack
                                  color="dark"
                                  image="https://w0.peakpx.com/wallpaper/240/377/HD-wallpaper-bedroom-hotel-room-light-design-modern-apartment-interior-idea-modern-design.jpg"
                                  title=""
                                  description="Les prix des chambres varient en fonction de la demande, de la saison et de l'emplacement."
                                  action={{ type: "internal", route: "/", label: "" }}
                                />
                              </RotatingCard>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RotatingCard>
                                <RotatingCardFront
                                  image="https://blackcars.gumlet.io/assets/images/hero.png?mode=fit&w=480&dpr=2.6"
                                  icon="local_taxi"
                                  title={<>VTC</>}
                                  color="dark"
                                  description="Voir plus"
                                />
                                <RotatingCardBack
                                  color="dark"
                                  image="https://blackcars.gumlet.io/assets/images/hero.png?mode=fit&w=480&dpr=2.6"
                                  title=""
                                  description="Les prix changent selon les conditions météo, l'affluence ou encore le lieu."
                                  action={{ type: "internal", route: "/", label: "" }}
                                />
                              </RotatingCard>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RotatingCard>
                                <RotatingCardFront
                                  image="https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Stade_de_France_1000_023.jpg/1200px-Stade_de_France_1000_023.jpg"
                                  icon="stadium"
                                  title={<>Sport</>}
                                  color="dark"
                                  description="Voir plus"
                                />
                                <RotatingCardBack
                                  color="dark"
                                  image="https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Stade_de_France_1000_023.jpg/1200px-Stade_de_France_1000_023.jpg"
                                  title=""
                                  description="Les tarifs des billets dans un stade varient en fonction de l'emplacement ainsi que de l'affluence."
                                  action={{ type: "internal", route: "/", label: "" }}
                                />
                              </RotatingCard>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Container>
                    </MKBox>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </MKBox>
          <Grid
            container
            item
            xs={12}
            lg={7}
            justifyContent="center"
            mx="auto"
            textAlign="center"
            pb={6}
          >
            <MKTypography variant="h2" mb={3}>
              <span className="fontKThin">
                Il est temps de passer à <span className="bigWordThin">l&apos;action</span>
              </span>
              <br />
              {/* <span className="fontKionaGold">niveau supérieur</span> */}
            </MKTypography>
            <MKTypography variant="body1" color="text">
              <span className="fontPara">
                Associez vos meilleures tables avec notre système de tarification dynamique, et
                augmentez à la fois vos revenus et la satisfaction de vos clients.
              </span>
            </MKTypography>
          </Grid>
          {/* Associations */}
          <MKBox component="section" position="relative" py={14}>
            <Container sx={{ maxWidth: "100% !important" }}>
              <Grid container item xs={11} sx={{ mx: "auto" }}>
                <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
                  <Grid container>
                    <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
                      <MKBox
                        component="img"
                        src="https://img.freepik.com/photos-gratuite/sport-equipe-mains-air-joyeux-souriant-heureux-exercice-ensemble-mode-vie-amis-amour-relation-unite-gens-ventilateur-mains-gagnants_343629-260.jpg"
                        alt="image"
                        borderRadius="md"
                        maxWidth="300px"
                        width="100%"
                        position="relative"
                        mt={-12}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      xl={7}
                      sx={{
                        position: "relative",
                        px: { xs: 6, md: 2 },
                        mt: { xs: 3, md: 0 },
                        my: { xs: 0, md: "auto" },
                        color: ({ palette: { white } }) => white.main,
                      }}
                    >
                      <VolunteerActivismIcon fontSize="large" color="light" />
                      {/* <Icon fontSize="large">format_quote</Icon> */}
                      <MKTypography variant="h3" my={1} mb={3} color="light">
                        <span className="fontKThin">
                          En réservant une table via Tablaba, vous alliez{" "}
                          <span className="bigWordThin">plaisir</span> et{" "}
                          <span className="bigWordThin">solidarité</span>.
                        </span>
                      </MKTypography>
                      <MKTypography
                        variant="body1"
                        color="white"
                        fontWeight="light"
                        mb={2}
                        sx={{ fontSize: "1.125rem" }}
                      >
                        <span className="fontPara">
                          Pour chaque réservation payante effectuée via l&apos;interface Tablaba, 10
                          centimes minimum sont reversés à des œuvres caritatives.
                        </span>
                      </MKTypography>
                    </Grid>
                    <Grid item xs={1} />
                    {/* <Grid
                      item
                      xs={12}
                      xl={2}
                      sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
                    >
                      <MKTypography variant="h3" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                        10 +
                      </MKTypography>
                      <MKTypography
                        component="p"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        mb={2}
                      >
                        Nous disposons d&apos;un pannel importants d&apos;organisation partenaires.
                      </MKTypography>
                      <MKTypography
                        component="a"
                        href="#"
                        variant="button"
                        color="white"
                        fontWeight="regular"
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: `translateX(3px)`,
                            transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },

                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: `translateX(6px)`,
                          },
                        }}
                      >
                        Voir toutes les associations
                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                      </MKTypography>
                    </Grid> */}
                  </Grid>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
          {/* Contact */}
          <MKBox component="section" py={4}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={10}
                lg={7}
                mx="auto"
                mb={{ xs: 0, md: 6 }}
                textAlign="center"
              >
                <MKTypography variant="h1" mb={1}>
                  <span className="fontKionaGold">Nous Contacter</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontPara">
                    Nous tâchons de répondre dans les plus brefs délais à vos demandes.
                  </span>
                </MKTypography>
              </Grid>
              <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                  <MKBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput success label={<span className="fontPara">Nom</span>} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          success
                          type="email"
                          label={<span className="fontPara">Email</span>}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          success
                          label={<span className="fontPara">Email</span>}
                          multiline
                          fullWidth
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={6}>
                      <MKButton type="submit" variant="outlined" color="goldCustom">
                        Envoyer
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
        </Card>
      </div>
      <MKBox pt={0} px={0} mt={0} bgColor="#0F2022">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
