import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const PUBLIC_KEY =
  "pk_test_51MsTi3AOEqRg7y27XjyVZMBLlflarkYdh9zag4fdAdK4roV7lcS906vyqL8bfLCVYtB92xxAZQ7l1R862HlX1e2100lAJ4VhYw";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

function Stripe() {
  return (
    <Elements stripe={stripeTestPromise}>
      <CheckoutForm />
    </Elements>
  );
}

export default Stripe;
