/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
import "pages/Home/style.css";
import "./carousel.css";

function SimpleBookingCard({ image, title, description, guests, action, fun, fun2 }) {
  return (
    <Card
      sx={{
        backgroundColor: "#11363B",
      }}
    >
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox p={3} mt={-2}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <MKBox color="#fff">
            <Icon fontSize="small" color="light">
              {"people"}
            </Icon>
          </MKBox>
          <MKTypography
            style={{ marginLeft: "8px", marginTop: "3px" }}
            display="block"
            variant="button"
            color="text"
            fontWeight="regular"
            mb={0.75}
          >
            <span className="fontParaPetitWhite">{guests}</span>
          </MKTypography>
        </div>
        <MKTypography display="inline" variant="h5" fontWeight="bold">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            <span className="fontParaPetitWhite">{description}</span>
          </MKTypography>
        </MKBox>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            onClick={() => {
              fun();
            }}
          >
            <MKButton variant="outlined" size="small" color="goldCustom">
              {action.label}
            </MKButton>
          </div>
          <div
            onClick={() => {
              fun2();
            }}
          >
            <MKButton variant="outlined" size="small" color="goldCustom" style={{ marginLeft: 15 }}>
              <span className="fontBtn2">Fermer</span>
            </MKButton>
          </div>
        </div>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the SimpleBookingCard
SimpleBookingCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  guests: PropTypes.string.isRequired,
  fun: PropTypes.func.isRequired,
  fun2: PropTypes.func.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SimpleBookingCard;
