/* eslint-disable no-unused-vars */

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Otis Kit PRO themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";
import Home from "pages/Home";
import Propos from "pages/Propos";
import Services from "pages/Services";
import Tarifs from "pages/Tarifs";
import Engagement from "pages/Engagement";
import Restaurants from "pages/Restaurants";
// Otis Kit PRO routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <div className="cursor" /> */}
      {/* <Routes>
        {getRoutes(routes)}
        <Route path="" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/services" element={<Navigate to="/services" />} />
        <Route path="/a-propos" element={<Navigate to="/a-propos" />} />
      </Routes> */}
      {/* This down */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/tarifs" element={<Tarifs />} />
        <Route path="/a-propos" element={<Propos />} />
        <Route path="/engagement" element={<Engagement />} />
        <Route path="/restaurants" element={<Restaurants />} />
      </Routes>
    </ThemeProvider>
  );
}
