/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
import Divider from "@mui/material/Divider";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq";
import Features from "pages/Support/HelpCenter/sections/Features";
import Contact from "pages/Support/HelpCenter/sections/Contact";
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";
import bgImage1 from "assets/images/examples/color1.jpg";
import bgImage2 from "assets/images/examples/color3.jpg";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
import "cursorScript";

// Images
import bgImage from "assets/images/bg3.jpg";

function HelpCenter() {
  const [collapse, setCollapse] = useState(false);
  const headerRef = useRef(null);
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -4,
    });

    return () => parallax.destroy();
  }, []);
  const data = [
    {
      icon: "credit_card",
      title: "Compatible multi-devises",
      items: ["120+ global currenices", "Global payment"],
    },
    {
      icon: "support_agent",
      title: "24/7 email & téléphone",
      items: ["24/7 support", "Fast responses"],
    },
    {
      icon: "biotech",
      title: "Mises à jour régulières",
      items: ["Custom apps", "Best technologies"],
    },
    {
      icon: "bolt",
      title: "Paiement rapide et sécurisé",
      items: ["Full time access", "Transparent transactions"],
    },
    {
      icon: "receipt_long",
      title: "Reporting financier",
      items: ["5.000+ archives", "Real-time reporting"],
    },
    {
      icon: "group",
      title: "Intégration prise en compte",
      items: ["Over 100 extensions", "Developer Dashboard"],
    },
  ];
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
          label: "Commencer",
          color: "info",
        }}
        sticky
      />
      <div style={{ backgroundColor: "#0F2022" }}>
        <MKBox
          ref={headerRef}
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.7),
                rgba(gradients.dark.state, 0.7)
              )}, url("https://restaurantsphere.com/wp-content/uploads/2022/09/restaurant-sphere-paris-slider-salle-2-bis.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h2"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="f">Tarifs</span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "#0B191B",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
        >
          {/* Tarifs */}
          <MKBox component="section" py={{ xs: 0, lg: 7 }} sx={{ backgroundColor: "#0B191B" }}>
            <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 0 }}>
              <Grid
                container
                item
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={10}
                lg={5}
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <MKTypography variant="h3" mt={3}>
                  <span className="fontKionaGold">Une tarification à la carte</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontThin">
                    Tablaba est le seul service à pouvoir prétendre à un tel niveau de performance
                    en termes de rentabilité de vos tables
                  </span>
                </MKTypography>
              </Grid>
            </Container>
            <MKBox mt={-16}>
              <Container>
                <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} lg={4}>
                      <DefaultPricingCard
                        color="goldCustom"
                        split="Offrez aux clients la possibilité de choisir leur table et partagez les profits avec nous. Vous gardez 70% sur tous les revenus, et sans aucun frais de départ !"
                        badge={{ color: "dark", label: "starter" }}
                        price={{
                          currency: "€",
                          value: 0,
                          type: "mo",
                        }}
                        specifications={
                          [
                            /* { label: "Scan de votre restaurant", includes: true },
                          { label: "Tarification dynamique", includes: true },
                          { label: "Accompgnement", includes: true },
                          { label: "Aucun frais d'installation", includes: true }, */
                          ]
                        }
                        action={{
                          type: "internal",
                          route: "/",
                          color: "dark",
                          label: "join",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <DefaultPricingCard
                        color="info"
                        split="Si vous souhaitez offrir à vos clients la possibilité de réserver une table spécifique sans frais supplémentaires, cette offre est faite pour vous !"
                        badge={{ color: "dark", label: "starter" }}
                        price={{
                          currency: "€",
                          value: 199,
                          type: "mo",
                        }}
                        specifications={
                          [
                            /* { label: "Scan de votre restaurant", includes: true },
                          { label: "Tarification dynamique", includes: true },
                          { label: "Accompgnement", includes: true },
                          { label: "Frais d'installation", includes: true }, */
                          ]
                        }
                        action={{
                          type: "internal",
                          route: "/",
                          color: "dark",
                          label: "join",
                        }}
                      />
                    </Grid>
                  </Grid>
                </MKBox>
              </Container>
            </MKBox>
          </MKBox>
          {/* Services */}
          <MKBox component="section" py={2} px={1}>
            <Container>
              {/* <Grid container item xs={12} lg={8} >
                <MKTypography variant="h3">Read More About Us</MKTypography>
                <MKTypography variant="body2" fontWeight="regular" color="text">
                  Pain is what we go through as we become older. We get insulted by others, lose
                  trust for those others. We get back stabbed by friends. It becomes harder for us
                  to give others a hand.
                </MKTypography>
              </Grid> */}
              <Grid
                container
                // sx={{ mt: 6 }}
                alignItems="center"
                sx={{ mt: 6, mx: "auto", textAlign: "center" }}
              >
                {data.map(({ icon, title, items }) => (
                  <Grid key={icon} item xs={12} md={4}>
                    <MKBox py={2} pr={2}>
                      <MKTypography variant="h3" color="light">
                        <Icon>{icon}</Icon>
                      </MKTypography>
                      <MKTypography variant="h5" mt={2} mb={3}>
                        <span className="fontKionaGold">{title}</span>
                      </MKTypography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {items.map((item) => (
                          <MKBox key={item} display="flex" lineHeight={1.25}>
                            <MKTypography variant="body1" color="goldCustom">
                              <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                            </MKTypography>
                            <MKBox pl={2}>
                              <MKTypography variant="body2" color="text" fontWeight="bold">
                                <span className="fontParaPetit">{item}</span>
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                        ))}
                      </div>
                    </MKBox>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </MKBox>
          {/* Performance 2 */}
          {/* <MKBox component="section" py={6}>
            <Container>
              <Grid
                container
                item
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={10}
                lg={5}
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <MKTypography variant="h3" mt={3}>
                  <span className="fontKionaGold">Un service Gratuit</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontThin">
                    Tablaba est le seul service à pouvoir prétendre à un tel niveau de performance
                    en termes de rentabilité de vos tables
                  </span>
                </MKTypography>
              </Grid>
              <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }} mb={16}>
                <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                  <MKTypography variant="h4" color="text" mb={1} textAlign="justify">
                    <span className="fontKionaGold">Diversifiez vos revenus</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                      Au lieu de distribuer aux hasard vos meilleures places ou de les réserver aux
                      clients habituels, profitez de la Technologie Tablata pour diversifier vos
                      sources de revenus et satisfaire tous vos invités.
                    </span>
                  </MKTypography>
                  <MKTypography variant="h4" color="text" mb={1} textAlign="justify">
                    <span className="fontKionaGold">Optimisez l’expérience client</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                      Le client a toujours le choix d&apos;opter pour le service premium ou non. En
                      améliorant l&apos;accueil de la clientèle exigeante, vous pourrez vous
                      concentrer sur d&apos;autres tâches essentielles au bon fonctionnement de
                      votre entreprise.
                    </span>
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
                  <DefaultBackgroundCard
                    image={bgImage2}
                    label="social activities"
                    title="Working on Wallstreet is Not So Easy"
                    description="There’s nothing I really wanted to do in life that I wasn’t able to get good at. I’m not really specifically talented at anything except for the ability to learn."
                    action={{
                      type: "internal",
                      route: "/pages/support/help-center",
                      label: "get started",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
                  <DefaultBackgroundCard
                    image={bgImage1}
                    label="website visitors"
                    title="The Best Productivity Apps on Market"
                    description="As we live, our hearts turn colder. Cause pain is what we go through as we become older. We get insulted by others, lose trust for those others."
                    action={{
                      type: "internal",
                      route: "/pages/support/help-center",
                      label: "get started",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                      Démarquez vous de vos concurrents en offrant une expérience de réservation
                      unique à vos clients. Ils pourront se promener à loisir dans votre espace
                      commercial et choisir leur propre table.
                      <br />
                      <br />
                      De même que les cinéphiles choisissent leur salle de cinéma en fonction de
                      l&apos;équipement pour vivre la meilleure expérience. Offrez à vos clients la
                      possibilité de choisir la place qu&apos;ils désirent.
                      <br />
                      <br />
                      Nous offrons une solution gratuite et une assistance en permanence pour vous
                      accompagner dans ce processus et améliorer vos rendement.
                    </span>
                  </MKTypography>
                </Grid>
              </Grid>
            </Container>
          </MKBox> */}
          {/* FAQ */}
          <MKBox component="section" py={8}>
            <Container>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={6} my={6}>
                  <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
                    <span className="fontKionaGold">Questions fréquentes</span>
                  </MKTypography>
                  <MKBox mb={2}>
                    <MKTypography variant="body1" align="center" color="text">
                      <span className="fontPara">
                        Vous pouvez également nous contacter par email pour d&apos;autres questions:
                        contact@tablaba.com
                      </span>
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FaqCollapse
                    title={<span className="fontBold">Question 1</span>}
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 2</span>}
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 3</span>}
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 4</span>}
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 5</span>}
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* <Features /> */}
          {/* Nous contacter */}
          <MKBox component="section" py={8}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={10}
                lg={7}
                mx="auto"
                mb={{ xs: 0, md: 6 }}
                textAlign="center"
              >
                <MKTypography variant="h2" mb={1}>
                  <span className="fontKionaGold">Nous Contacter</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontPara">
                    Nous tâchons de répondre dans les plus brefs délais à vos demandes.
                  </span>
                </MKTypography>
              </Grid>
              <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                  <MKBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput label={<span className="fontPara">Nom</span>} fullWidth success />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          type="email"
                          label={<span className="fontPara">Email</span>}
                          fullWidth
                          success
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          label={<span className="fontPara">Email</span>}
                          multiline
                          fullWidth
                          rows={6}
                          success
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={6}>
                      <MKButton type="submit" variant="outlined" color="goldCustom">
                        Envoyer
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
        </Card>
      </div>
      <MKBox pt={0} px={0} mt={0} bgColor="#0F2022">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HelpCenter;
