/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React from "react";
import { CardElement, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import axios from "axios";
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      console.log("Token Généré", paymentMethod);
      // envoie du token au backend
      try {
        const { id } = paymentMethod;
        const response = await axios.post("http://localhost:8080/stripe/charge", {
          amount: 100,
          id,
        });
        if (response.data.success) {
          console.log("Paiement réussi");
        }
      } catch (error) {
        console.log("Erreur!", error);
      }
    } else {
      console.log(error.message);
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "#fff",
        fontFamily: "Kiona-Regular, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#fff",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="">
        <CardElement
          /* options={{
            hidePostalCode: true,
          }} */
          options={cardStyle}
          className="CardElementCSS"
        />
      </form>
      <div onClick={handleSubmit}>{/* <p>Payer</p> */}</div>
    </>
  );
}

/* 
agence: renaissance & socialkillers
l'air du temps
david toutan

*/
