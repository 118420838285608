/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState, React } from "react";
import Carousel from "react-elastic-carousel";
import styles from "./mystyle.module.css";
import "./carousel.css";
import Cal, { getCalApi } from "@calcom/embed-react";
import Icon from "@mui/material/Icon";
import SimpleBookingCard from "./bookCard";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { createRoot } from "react-dom/client";
// import "./player";
import "pages/Home/style.css";
import getDataMetareal from "hooks/airtableAPI";
import "./navMenu.css";
import "./calStyle.css";
import Calendar from "react-calendar";
import AnimateHeight from "react-animate-height";
import Lottie from "react-lottie";
import animationData from "assets/lotties/loader.json";
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import StripeContainer from "./stripe/StripeContainer";

function Restaurants() {
  const container = useRef(null);
  /* DATE CHOISIE DANS LE CALENCRIER */
  const [value, onChange] = useState(new Date());
  /* METAREAL PLAYER */
  const [playerState, setPlayerState] = useState(false);
  /* INFORMATIONS DE LA TABLE (POPUP DETAILS) */
  const [tableData, setTableData] = useState();
  /* INFORMATIONS AIRTABLE DE LA TABLE  */
  const [metarealData, setMetarealData] = useState([]);
  /* OUI SI RESERVATION SUR TABLE SELECTIONNEE */
  const [tablePrecise, setTablePrecise] = useState(false);
  /* SI OUI AFFICHE LE POPUP DETAIL DE LA TABLE */
  const [bookRender, setBookRender] = useState(false);
  /* SI OUI SELECTIONNE LA TABLE DANS LE CAROUSEL */
  const [classActive, setClassActive] = useState(false);
  /* POPUP DE RESERVATION DE TABLE */
  const [tablePopup, setTablePopup] = useState(false);
  /* DATE SELECTIONNEE DANS LE POPUP */
  const [dateSelected, setDateSelected] = useState(false);
  /* NOMBRE DE PERSONNES SELECTIONNEE DANS LE POPUP */
  const [guestSelected, setGuestSelected] = useState(false);
  /* FILTRE ACTIF  */
  const [tablesFilter, setTablesFilter] = useState(false);
  const [houreHeight, setHoureHeight] = useState(0);
  const [loadingTables, setLoadingTables] = useState(false);
  const [fadeProp, setFadeProp] = useState({ fade: "fade-in" });
  const [guestValue, setGuestValue] = useState();
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(7);
  const [houreDiv, setHoureDiv] = useState();

  const heuresDispoEx = ["19h00", "19h30", "20h00", "20h30", "21h00", "21h30"];
  const priceDispoEx = ["0", "5", "10", "15", "20", "10"];
  const DynaPriceTables = {
    "19h00": 0,
    "19h30": 5,
    "20h00": 10,
    "20h30": 15,
    "21h00": 20,
    "21h30": 10,
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    async function fetchData(restaurant) {
      let tableIntermediaire = [];
      const dataMetareal = await getDataMetareal(restaurant);
      for (let table of dataMetareal) {
        tableIntermediaire.push(table.fields);
      }
      setMetarealData(tableIntermediaire);
    }
    fetchData("Test01");
    const player = MetarealPlayerSDK.create("myWebApp");
    setPlayerState(player);
    const tourURL =
      "https://tour-uk.metareal.com/apps/player?asset=20fb9030-26cb-4164-9c3d-297fa60245a6&_gl=1*771lrp*_ga*MTIwNzcyMTMzMy4xNjc3NDE5MzA1*_ga_SKM1DSFZ4R*MTY4MDUyODE5Mi4zNy4xLjE2ODA1Mjg4MzYuNTEuMC4w";
    // Load the tour
    player.load(tourURL, () => {
      player.startShowcase();
    });
    /* */
  }, []);

  function searchLabels(playerSDKElement) {
    let labels = [];
    for (let index in playerSDKElement) {
      if (
        !index.startsWith("_") &&
        typeof playerSDKElement[index] === "object" &&
        !["dictionary"].includes(index) &&
        index === "tour"
      ) {
        let element = playerSDKElement[index];
        for (let label of element.labels) {
          labels.push(label);
        }
      }
    }
    return labels;
  }

  const angleConvertor = (angle) => (angle * 180) / Math.PI;
  const angleVectConvertor = (x, y, z) => ({
    x: angleConvertor(x),
    y: angleConvertor(y),
    z: angleConvertor(z),
  });
  const navigateToTag = async (pan, rot, table) => {
    setFadeProp({
      fade: "fade-out",
    });
    setTimeout(() => {
      playerState.moveToPanorama(pan, "teleport");
      rotate(rot, table);
    }, 500);
    setTimeout(() => {
      setFadeProp({
        fade: "fade-in",
      });
    }, 650);
  };
  const rotate = async (rot, table) => {
    playerState.setTourViewCamera(rot, 88.10000000000018);
    setTableData(table);
    setBookRender(true);
  };
  const renderCalFun = () => {
    /* setCalRender(true); */
    setBookRender(false);
    setTablePrecise(true);
    setTablePopup(!tablePopup);
  };
  // unselkect table active
  if (classActive !== false) {
    playerState.setEventListener("moving", () => {
      setClassActive(false);
      setBookRender(false);
      setTablePrecise(false);
    });
  }

  const ToggleClass = () => {
    const toggler = document.querySelector(".menu__toggler");
    const menu = document.querySelector(".menu");
    // setClassActive(!classActive);
    toggler.classList.toggle("active");
    menu.classList.toggle("active");
  };

  const handleLeftClick = () => {
    if (minValue > 1) {
      setMinValue(minValue - 7);
      setMaxValue(maxValue - 7);
    }
  };

  const handleRightClick = () => {
    if (maxValue < 20) {
      setMinValue(minValue + 7);
      setMaxValue(maxValue + 7);
    }
  };

  const handleNumberClick = (number) => {
    setGuestValue(number);
  };

  const renderNumbers = () => {
    const numbers = [];
    for (let i = minValue; i <= maxValue; i++) {
      numbers.push(
        <div
          className={`${tablePrecise && i > Number(tableData.Guests) ? "calDesactive" : null} ${
            guestValue === i ? "numberPickerSelected" : "numberPicker"
          }`}
          key={i}
          onClick={() => {
            const element = document.getElementById("section-cal");
            handleNumberClick(i);
            element.scrollIntoView({ behavior: "smooth", inline: "end" });
            setGuestSelected(true);
          }}
        >
          {i}
        </div>
      );
    }
    return numbers;
  };

  return (
    <div>
      <div className={classActive ? "app" : "app"}>
        {/* MENU */}
        <div className="menu">
          <p>Section #1</p>
          <p>Section #2</p>
          <p>Section #3</p>
        </div>
        <div onClick={ToggleClass} className="menu__toggler">
          <span />
        </div>
        {/* NAVIGATION 3D */}
        <div className={fadeProp.fade}>
          <div className="webapp" id="myWebApp" ref={container} />
        </div>
        {/* TROUVER UNE TABLE CTA */}
        {!tablePopup && !tablesFilter && classActive === false ? (
          <div
            className="trouver-table"
            onClick={() => {
              setTablePopup(!tablePopup);
            }}
            style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <div
              style={{
                marginLeft: 0,
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3"
                  y="5"
                  width="18"
                  height="16"
                  rx="3"
                  stroke="#F5ECD0"
                  strokeWidth="1.5"
                />
                <path
                  d="M8 11H16"
                  stroke="#F5ECD0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 7V3"
                  stroke="#F5ECD0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 7V3"
                  stroke="#F5ECD0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <MKTypography display="inline" variant="h6" textTransform="uppercase">
              <span className="fontKionaGoldBold">Trouver une table</span>
            </MKTypography>
          </div>
        ) : null}
        {/* TABLES FILTER */}
        {!tablePopup && tablesFilter ? (
          <div
            className="trouver-table"
            onClick={() => {
              setTablesFilter(false);
            }}
            style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <div
              style={{
                marginLeft: 0,
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 7L17 17" stroke="#F5ECD0" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M7 17L17 7" stroke="#F5ECD0" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </div>
            <MKTypography display="inline" variant="h6" textTransform="uppercase">
              <span className="fontKionaGoldBold">Annuler le filtre</span>
            </MKTypography>
          </div>
        ) : null}
        {/* DETAIL TABLE */}
        <div className={fadeProp.fade}>
          {bookRender && classActive !== false ? (
            <div className="bookCard">
              <SimpleBookingCard
                fun={renderCalFun}
                fun2={() => {
                  setBookRender(false);
                  setClassActive(false);
                  setTablePrecise(false);
                }}
                image={tableData["Picture URL"]}
                title={<span className="fontKionaGoldBold">{tableData.Table}</span>}
                description={tableData.Description}
                guests={tableData.Guests}
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "info",
                  label: "Réserver",
                }}
                sx={{
                  backgroundColor: "#11363B",
                }}
              />
            </div>
          ) : null}
        </div>
        {/* TROUVER UNE TABLE POPUP */}
        {tablePopup ? (
          <div className="calandarPopup">
            {/* CLOSE BUTTON */}
            <div
              className="calClose"
              onClick={() => {
                setTablePopup(!tablePopup);
                if (tablePrecise) {
                  setBookRender(true);
                }
              }}
            >
              <Icon style={{ color: "#C39E58" }} fontSize="small">
                {"close"}
              </Icon>
            </div>
            <div>
              {/* TITRE */}
              <div>
                {tablePrecise ? (
                  <p className="trouverTable">Réservation</p>
                ) : (
                  <p className="trouverTable">Trouver une table</p>
                )}
              </div>
              {/* LOGO HEADER */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  borderRadius: 10,
                  backgroundColor: "#102022",
                  marginBottom: 20,
                  paddingBottom: 15,
                  paddingTop: 15,
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div
                  onClick={() => {
                    const element = document.getElementById("section-pers");
                    element.scrollIntoView({ behavior: "smooth", inline: "start" });
                  }}
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                  className="logoHeader"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="7" r="4" stroke="#F6EDD0" strokeWidth="1.5" />
                    <path
                      d="M11.8328 10.3626C12.4573 10.7659 13.2013 11 14 11C16.2092 11 18 9.20914 18 7C18 4.79086 16.2092 3 14 3C13.2969 3 12.6361 3.18142 12.062 3.5"
                      stroke="#F6EDD0"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M10 14C7.17219 14 4.73601 15.6768 3.63151 18.0903C2.94205 19.5969 4.34315 21 6 21H14C15.6569 21 17.058 19.5969 16.3685 18.0903C15.264 15.6768 12.8278 14 10 14Z"
                      stroke="#F6EDD0"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M7 21H12H18C19.6569 21 21.058 19.5969 20.3685 18.0903C19.264 15.6768 16.8278 14 14 14C13.305 14 12.6337 14.1013 12 14.2899"
                      stroke="#F6EDD0"
                      strokeWidth="1.5"
                    />
                  </svg>
                  {/* <p className="headerCalpopup">Invités</p> */}
                </div>
                <div
                  onClick={() => {
                    const element = document.getElementById("section-cal");
                    if (guestSelected) {
                      element.scrollIntoView({ behavior: "smooth", inline: "start" });
                    }
                  }}
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                  className="logoHeader"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="3"
                      y="5"
                      width="18"
                      height="16"
                      rx="3"
                      stroke={guestSelected ? "#F6EDD0" : "#7B8283"}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M8 11H16"
                      stroke={guestSelected ? "#F6EDD0" : "#7B8283"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 7V3"
                      stroke={guestSelected ? "#F6EDD0" : "#7B8283"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 7V3"
                      stroke={guestSelected ? "#F6EDD0" : "#7B8283"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {/* <p className="headerCalpopup">Date</p> */}
                </div>
                <div
                  onClick={() => {
                    const element = document.getElementById("section-houre");
                    if (dateSelected) {
                      element.scrollIntoView({ behavior: "smooth", inline: "start" });
                    }
                  }}
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                  className="logoHeader"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                      stroke={dateSelected ? "#F6EDD0" : "#7B8283"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 6V10.0118C12 10.7455 12 11.1124 12.0829 11.4577C12.1564 11.7638 12.2776 12.0564 12.4421 12.3249C12.6276 12.6276 12.887 12.887 13.4059 13.4059L16 16"
                      stroke={dateSelected ? "#F6EDD0" : "#7B8283"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>

                  {/* <p className="headerCalpopup">Heure</p> */}
                </div>
              </div>
              <div
                style={{
                  overflowY: "scroll",
                  height: "370px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
                className="calContainer"
              >
                {/* NOMBRE DE PERSONNES */}
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "17px",
                    }}
                    id="section-pers"
                  >
                    <p className="nombrePers">Nombre de personnes</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <div
                        onClick={handleLeftClick}
                        className="Arrow"
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          marginRight: "35px",
                          color: "#D8AD61",
                        }}
                      >
                        ‹
                      </div>
                      <div
                        className="Arrow"
                        onClick={handleRightClick}
                        style={{ fontSize: "18px", fontWeight: "400", color: "#D8AD61" }}
                      >
                        ›
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    {renderNumbers()}
                  </div>
                </div>
                {/* CALENDRIER */}
                <div id="section-cal" style={{ maringTop: "-10px" }}>
                  <Calendar
                    className={guestSelected ? "calActive" : "calDesactive"}
                    onChange={onChange}
                    value={value}
                    onClickDay={(day) => {
                      const element = document.getElementById("section-houre");
                      setDateSelected(true); /* setHoureHeight("auto") */
                      element.scrollIntoView({ behavior: "smooth", inline: "end" });
                    }}
                  />
                </div>
                {/* HEURES */}
                <div
                  className={dateSelected ? "houreActive" : "houreDesactive"}
                  id="section-houre"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  {heuresDispoEx.map((item) => (
                    <div
                      className={houreDiv === item ? "houreDivActive" : "houreDiv"}
                      onClick={() => {
                        setHoureHeight("auto");
                        setHoureDiv(item);
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      {tablePrecise ? <p className="prixCal">10€</p> : null}
                      <p className="houreCal">{item}</p>
                    </div>
                  ))}
                </div>
                {tablePrecise ? (
                  <>
                    {/* INFROMATIONS CONTACT */}
                    <div>
                      <p className="nombrePers">Informations de contact</p>
                      <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>
                        <MKInput success label="Nom et Prénom" fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                        <MKInput success type="email" label="Email" fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                        <MKInput success type="phone" label="Téléphone" fullWidth />
                      </Grid>
                    </div>
                    {/* INFROMATIONS PAIEMENT */}
                    <div style={{ marginTop: "15px", marginBottom: "20px" }}>
                      <p className="nombrePers" style={{ marginBottom: "10px" }}>
                        Paiement
                      </p>
                      <StripeContainer />
                    </div>
                  </>
                ) : null}
              </div>
              {/* BOUTON TROUVER TABLE */}
              <AnimateHeight
                duration={500}
                height={houreHeight} // see props documentation below
              >
                <div
                  className="boutonTrouverTable"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                  onClick={() => {
                    // setLoadingTables(!loadingTables);
                    // setPopupHeight(0);
                    setTablesFilter(true);
                    setTablePopup(!tablePopup);
                  }}
                >
                  {/*  */}
                  {loadingTables ? (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      style={{ marginTop: "-30px", marginBottom: "-30px" }}
                    />
                  ) : (
                    <div>
                      {tablePrecise ? (
                        <p className="texteTrouverTable">Réserver</p>
                      ) : (
                        <p className="texteTrouverTable">Voir les tables</p>
                      )}
                    </div>
                  )}
                </div>
              </AnimateHeight>
            </div>
          </div>
        ) : null}
        {/* CAROUSEL TABLES */}
        {!tablePopup ? (
          <div className="carousel-custom">
            {Array.isArray(metarealData) && metarealData.length > 0 ? (
              <Carousel itemsToShow={3}>
                {metarealData
                  .sort((a, b) => {
                    if (a.Table < b.Table) {
                      return -1;
                    }
                    if (a.Table > b.Table) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((table) => (
                    <div
                      key={table["Pano ID"]}
                      className="click-custom"
                      onClick={() => {
                        setClassActive(table["Pano ID"]);
                        navigateToTag(
                          { id: table["Pano ID"] },
                          angleVectConvertor(table.rot_x, table.rot_y, 0),
                          table
                        );
                      }}
                    >
                      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
                        <MKBox
                          className={
                            classActive === table["Pano ID"] ? "table-active" : "image-custom"
                          }
                          component="img"
                          src={table["Picture URL"]}
                          alt={table.Table}
                          borderRadius="lg"
                          shadow="md"
                          width="142px"
                          height="80px"
                          position="relative"
                          zIndex={10}
                        />
                        <MKBox
                          className="shadow-custom"
                          borderRadius="lg"
                          shadow="md"
                          width="142px"
                          height="80px"
                          position="absolute"
                          left={0}
                          top={0}
                          sx={{
                            backgroundImage: `${table}["Picture URL"]`,
                            transform: "scale(0.94)",
                            filter: "blur(12px)",
                            backgroundSize: "cover",
                          }}
                        />
                      </MKBox>
                      <MKBox className="text-custom" mt={-2}>
                        <MKTypography display="inline" variant="h6" textTransform="uppercase">
                          <span className="fontKionaGoldBold">{table.Table}</span>
                        </MKTypography>
                      </MKBox>
                    </div>
                  ))}
              </Carousel>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Restaurants;
