/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
import Divider from "@mui/material/Divider";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq";
import Features from "pages/Support/HelpCenter/sections/Features";
import Contact from "pages/Support/HelpCenter/sections/Contact";
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";
import bgImage1 from "assets/images/examples/color1.jpg";
import bgImage2 from "assets/images/examples/color3.jpg";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// Images
import bgImage from "assets/images/bg3.jpg";

function HelpCenter() {
  const [collapse, setCollapse] = useState(false);
  const headerRef = useRef(null);
  const updateProperties = (elem, state) => {
    elem.style.setProperty("--x", `${state.x}px`);
    elem.style.setProperty("--y", `${state.y}px`);
    elem.style.setProperty("--width", `${state.width}px`);
    elem.style.setProperty("--height", `${state.height}px`);
    elem.style.setProperty("--radius", state.radius);
    elem.style.setProperty("--scale", state.scale);
  };

  document.querySelectorAll(".cursor").forEach((cursor) => {
    let onElement;

    const createState = (e) => {
      const defaultState = {
        x: e.clientX,
        y: e.clientY,
        width: 40,
        height: 40,
        radius: "50%",
      };

      const computedState = {};

      if (onElement != null) {
        const { top, left, width, height } = onElement.getBoundingClientRect();
        const radius = window.getComputedStyle(onElement).borderTopLeftRadius;

        computedState.x = left + width / 2;
        computedState.y = top + height / 2;
        computedState.width = width;
        computedState.height = height;
        computedState.radius = radius;
      }

      return {
        ...defaultState,
        ...computedState,
      };
    };

    document.addEventListener("mousemove", (e) => {
      const state = createState(e);
      updateProperties(cursor, state);
    });

    document.querySelectorAll("a, button").forEach((elem) => {
      elem.addEventListener("mouseenter", () => (onElement = elem));
      elem.addEventListener("mouseleave", () => (onElement = undefined));
    });
  });
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -4,
    });

    return () => parallax.destroy();
  }, []);
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
          label: "Commencer",
          color: "info",
        }}
        sticky
      />
      <div style={{ backgroundColor: "#0F2022" }}>
        <MKBox
          ref={headerRef}
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.7),
                rgba(gradients.dark.state, 0.7)
              )}, url("https://res.cloudinary.com/dbt1oxzwd/image/upload/v1679238229/photographe-hotel-luxe-normandie_vc1en2.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h2"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="f">Notre Service</span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "#0B191B",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
          bgColor="#0F2022"
        >
          {/* Performance 2 */}
          <MKBox component="section" py={6}>
            <Container>
              <Grid
                container
                item
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={10}
                lg={5}
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <MKTypography variant="h3" mt={3}>
                  <span className="fontKionaGold">Un service Gratuit</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontThin">
                    Tablaba est le seul service à pouvoir prétendre à un tel niveau de performance
                    en termes de rentabilité de vos tables
                  </span>
                </MKTypography>
              </Grid>
              <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }} mb={16}>
                <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                  <MKTypography variant="h4" color="text" mb={1} textAlign="justify">
                    <span className="fontKionaGold">Diversifiez vos revenus</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                      Au lieu de distribuer aux hasard vos meilleures places ou de les réserver aux
                      clients habituels, profitez de la Technologie Tablata pour diversifier vos
                      sources de revenus et satisfaire tous vos invités.
                    </span>
                  </MKTypography>
                  <MKTypography variant="h4" color="text" mb={1} textAlign="justify">
                    <span className="fontKionaGold">Optimisez l’expérience client</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                      Le client a toujours le choix d&apos;opter pour le service premium ou non. En
                      améliorant l&apos;accueil de la clientèle exigeante, vous pourrez vous
                      concentrer sur d&apos;autres tâches essentielles au bon fonctionnement de
                      votre entreprise.
                    </span>
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
                  <DefaultBackgroundCard
                    image="https://www.cvent.com/sites/default/files/image/2022-02/businessperson%20analyzing%20revenue%20management%20data%20on%20computer%20and%20spreadsheet.jpg"
                    label=""
                    title="Working on Wallstreet is Not So Easy"
                    description="There’s nothing I really wanted to do in life that I wasn’t able to get ."
                    action={{
                      type: "internal",
                      route: "/pages/support/help-center",
                      label: "get started",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
                  <DefaultBackgroundCard
                    image="https://media.istockphoto.com/id/623427218/fr/photo/belle-fille-textos-dans-le-caf%C3%A9.jpg?s=612x612&w=0&k=20&c=b7a4UrvDU6FFMV0AzJ-4T7t_-x8wLMSYeEsAfJctSVU="
                    label=""
                    title="The Best Productivity Apps on Market"
                    description="As we live, our hearts turn colder. Cause pain is what we go through"
                    action={{
                      type: "internal",
                      route: "/pages/support/help-center",
                      label: "get started",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
                  <MKTypography variant="h4" color="text" mb={1} textAlign="justify">
                    <span className="fontKionaGold">Démarquez vous</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                      Contrairement à vos concurrents offrez une expérience de réservation unique à
                      vos clients. Ils pourront se promener à loisir dans votre espace commercial et
                      choisir leur propre table.
                    </span>
                  </MKTypography>
                  <MKTypography variant="h4" color="text" mb={1} textAlign="justify">
                    <span className="fontKionaGold">Le client est roi</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                      De même que les cinéphiles choisissent leur salle de cinéma en fonction de
                      l&apos;équipement pour vivre la meilleure expérience. Offrez à vos clients la
                      possibilité de choisir la place qu&apos;ils désirent.
                    </span>
                  </MKTypography>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* Trois Axes */}
          <MKBox component="section" py={6}>
            <Container>
              <Grid
                container
                item
                xs={12}
                lg={8}
                flexDirection="column"
                textAlign="center"
                mx="auto"
                mb={3}
              >
                <MKTypography variant="h2" fontWeight="bold">
                  <span className="fontKionaGold">Les étapes clés</span>
                </MKTypography>
                {/* <MKTypography variant="body1">
                  <span className="fontThin">Nos services se décomposent en trois axes</span>
                </MKTypography> */}
              </Grid>
              <Grid container spacing={3} mt={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678919242/Capture_d_e%CC%81cran_2023-03-15_a%CC%80_23.27.15_cbdyjc.png"
                    label="Scan"
                    title={<span className="fontKBoldGold">Scannage de votre établissement</span>}
                    description={
                      <span className="fontParaPetitWhite">
                        La première impression que le client a de votre restaurant est cruciale car
                        c&apos;est dès la réservation que tout commence. Une fois la salle à manger,
                        vide et prête pour le service nous venons cartographier votre établissement,
                        et cela en moins d’une heure.
                      </span>
                    }
                    action={{
                      type: "internal",
                      route: "/sections/page-sections/applications",
                      label: "En savoir plus",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678981294/original_frkkea.avif"
                    label="Configuration"
                    title={<span className="fontKBoldGold">Choix des tables et tarifs</span>}
                    description={
                      <span className="fontParaPetitWhite">
                        Un expert identifiera à vos côtés les meilleures tables et les connectera à
                        notre algorithme de tarification dynamique. Les prix varirons en fonction de
                        la météo exceptionnelle, d&apos;un événement spécial, des tendances ou
                        encore d&apos;autres agents extérieurs.
                      </span>
                    }
                    action={{
                      type: "internal",
                      route: "/sections/page-sections/applications",
                      label: "En savoir plus",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678981394/accompagnement-du-createur-min_h5ntym.jpg"
                    label="Suivi"
                    title={<span className="fontKBoldGold">Accompagnement long terme</span>}
                    description={
                      <span className="fontParaPetitWhite">
                        Fixer le bon prix au bon moment nécessite une attention constante. Grâce au
                        soutien de nos algorithmes, nos experts surveillent constamment le marché
                        pour garantir les meilleurs rendements possibles.
                      </span>
                    }
                    action={{
                      type: "internal",
                      route: "/sections/page-sections/applications",
                      label: "En savoir plus",
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* Nos valeurs */}
          {/* <MKBox component="section" py={6}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                lg={6}
                sx={{ mx: "auto", pb: 3, textAlign: "center" }}
              >
                <MKTypography variant="h4" color="info" textGradient>
                  <span className="fontKionaGold">Nos valeurs</span>
                </MKTypography>
                <MKTypography variant="h2" my={1}>
                  Turn your idea into a startup
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We&apos;re constantly trying to express ourselves and actualize our dreams. If you
                  have the opportunity to play
                </MKTypography>
              </Grid>
              <Grid container spacing={3} sx={{ mt: 3 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <DefaultInfoCard
                    icon="groups"
                    title="Check our team"
                    description="We get insulted by others, lose trust for those others. We get back here to follow
                my dreams"
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DefaultInfoCard
                    icon="support_agent"
                    title="Support 24/7"
                    description="We get insulted by others, lose trust for those others. We get back here to follow
                my dreams"
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
                  <DefaultInfoCard
                    icon="update"
                    title="Unlimited revisions"
                    description="We get insulted by others, lose trust for those others. We get back here to follow
                my dreams"
                    direction="center"
                  />
                </Grid>
              </Grid>
            </Container>
          </MKBox> */}
          {/* Tarification */}
          <MKBox component="section" py={{ xs: 3, md: 12 }}>
            <Container>
              <Grid container alignItems="center">
                <Grid item xs={12} lg={5}>
                  <MKTypography variant="h3" my={1}>
                    <span className="fontKionaGold">Notre expertise en tarification dynamique</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                      L’équipe Tablaba est fiers d&apos;avoir développé des algorithmes de
                      tarification dynamique en restaurant hautement sophistiqués. Nous restons à la
                      pointe de l&apos;innovation pour offrir des solutions toujours plus
                      performantes à nos clients.
                    </span>
                  </MKTypography>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                  <Stack>
                    <AboutUsOption
                      icon="star"
                      content={
                        <>
                          Notre algorithme de tarification dynamique est le meilleur
                          <br />
                          existant sur le marché.
                        </>
                      }
                    />
                    <AboutUsOption
                      icon="settings"
                      content={
                        <>
                          Notre algorithme prend en compte une multitude de facteurs
                          <br />
                          pour garantir des prix justes et une rentabilité optimale.
                        </>
                      }
                    />
                    <AboutUsOption
                      icon="mediation"
                      content={
                        <>
                          Nous traitons des paramètres tels que l&apos;emplacement, l&apos;heure,
                          <br />
                          la date, la saisonnalité, la météo et les événements spéciaux.
                        </>
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* FAQ */}
          <MKBox component="section" py={2}>
            <Container>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={6} my={6}>
                  <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
                    <span className="fontKionaGold">Questions fréquentes</span>
                  </MKTypography>
                  <MKBox mb={2}>
                    <MKTypography variant="body1" align="center" color="text">
                      <span className="fontPara">
                        Vous pouvez également nous contacter par email pour d&apos;autres questions:
                        contact@tablaba.com
                      </span>
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FaqCollapse
                    title={<span className="fontBold">Question 1</span>}
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 2</span>}
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 3</span>}
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 4</span>}
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 5</span>}
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          {/* <Features /> */}
          {/* Nous contacter */}
          <MKBox component="section" py={8}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={10}
                lg={7}
                mx="auto"
                mb={{ xs: 0, md: 6 }}
                textAlign="center"
              >
                <MKTypography variant="h2" mb={1}>
                  <span className="fontKionaGold">Nous Contacter</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontPara">
                    Nous tâchons de répondre dans les plus brefs délais à vos demandes.
                  </span>
                </MKTypography>
              </Grid>
              <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                  <MKBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput label={<span className="fontPara">Nom</span>} fullWidth success />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          type="email"
                          label={<span className="fontPara">Email</span>}
                          fullWidth
                          success
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          label={<span className="fontPara">Email</span>}
                          multiline
                          fullWidth
                          rows={6}
                          success
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={6}>
                      <MKButton type="submit" variant="outlined" color="goldCustom">
                        Envoyer
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
        </Card>
      </div>
      <MKBox pt={0} px={0} mt={0} bgColor="#0F2022">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HelpCenter;
