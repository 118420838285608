/* eslint-disable no-unused-vars */
const AirtablePlus = require("airtable-plus");

const API_KEY = "keyMUjulb0qaiMdA6";
const BASE = "appl0JcKGh6niEr6K";

const TABLE_METAREAL = "Metareal";

const airtableMetareal = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: TABLE_METAREAL,
});

async function getDataMetareal(space) {
  const filter = `({Space} = '${space}')`;
  const res = await airtableMetareal.read({ filterByFormula: filter });
  return res;
}

export default getDataMetareal;
